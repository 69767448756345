import { forwardRef, useCallback, useMemo } from 'react'
import { EChartsHandle } from '../EchartsBase'
import { SQLChartProps } from '../SqlChart'
import { ColumnDef } from '@tanstack/react-table'
import { produce } from 'immer'
import { valueFormatter } from '../../../lib/metrics/formatter'
import { TabularDataColumnType } from '@sentio/service/common'
import { getDefaultValueConfig } from '../options/TableControls'
import { TableState, useTableState } from '../../../lib/data/use-table-state'
import DataGrid from '../../common/table/DataGrid'
import isEqual from 'lodash/isEqual'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { PopupMenuButton } from 'components/menu/PopupMenuButton'
import classNames from 'lib/classnames'

export const SqlTableChart = forwardRef<EChartsHandle, SQLChartProps>((props: SQLChartProps, ref) => {
  const { data, loading, config, onChangeConfig, error, chartType } = props

  function onResizeColumn(columWidths: { [col: string]: number | null }) {
    if (onChangeConfig && config) {
      onChangeConfig(
        produce(config, (draft) => {
          draft.tableConfig = draft.tableConfig || {}
          draft.tableConfig.columnWidths = draft.tableConfig.columnWidths || {}
          for (const col in columWidths) {
            const columWidth = columWidths[col]
            if (columWidth == null || columWidth == -1) {
              delete draft.tableConfig.columnWidths[col]
            } else {
              draft.tableConfig.columnWidths[col] = columWidth
            }
          }
        })
      )
    }
  }

  function resetResizeColumn() {
    if (onChangeConfig && config) {
      onChangeConfig(
        produce(config, (draft) => {
          if (draft.tableConfig?.columnWidths) {
            delete draft.tableConfig.columnWidths
          }
        })
      )
    }
  }

  const { columns, rows } = useMemo(() => {
    const rows = data?.result?.rows || []
    const columns: ColumnDef<any>[] = [
      {
        id: '__index',
        header: '#',
        accessorFn: (row, index) => index + 1,
        size: config?.tableConfig?.columnWidths?.['__index']
      }
    ]
    for (const col of data?.result?.columns || []) {
      columns.push({
        id: col,
        header: col,
        accessorFn: (row) => {
          let value = row[col]
          const columnType = data?.result?.columnTypes?.[col]
          if (columnType === TabularDataColumnType.TIME) {
            value = new Date(value)
          }
          const valueConfig = config?.tableConfig?.valueConfigs?.[col] || getDefaultValueConfig(columnType)
          const formatter = valueFormatter(valueConfig)
          return formatter(value)
        },
        size: config?.tableConfig?.columnWidths?.[col]
      })
    }
    return { rows: [...rows], columns }
  }, [data, config?.tableConfig])

  const onChangeTableState = useCallback(
    (state: TableState) => {
      onChangeConfig &&
        config &&
        onChangeConfig(
          produce(config, (draft) => {
            const tableConfig = draft.tableConfig || (draft.tableConfig = {})
            tableConfig.showColumns = state.showColumns
            tableConfig.sortColumns = state.sorting.map((s) => ({
              column: s.id,
              orderDesc: s.desc
            }))
            tableConfig.columnOrders = state.columnOrders
          })
        )
    },
    [config, onChangeConfig]
  )

  const {
    columnOrders,
    showColumns,
    onChangeColumnOrders,
    onShowColumnChange,
    sortingState,
    onChangeSorting,
    resetState
  } = useTableState(
    props.panelId || '',
    columns,
    {
      sorting: [],
      columnOrders: config?.tableConfig?.columnOrders || [],
      showColumns: config?.tableConfig?.showColumns || {}
    },
    onChangeTableState
  )

  const resetOrderDisabed = useMemo(() => {
    return isEqual(
      columns.map((c) => c.id!),
      columnOrders
    )
  }, [columnOrders, columns, config?.tableConfig?.columnOrders])

  const isColumnWidthSet = useMemo(() => {
    return Object.keys(config?.tableConfig?.columnWidths || {}).length > 0
  }, [config?.tableConfig?.columnWidths])

  if (error) {
    return (
      <div className="flex h-full w-full items-center text-center">
        <p className="mx-auto text-sm">{error}</p>
      </div>
    )
  }

  const allowEdit = props.allowEdit
  return (
    <>
      <DataGrid
        columns={allowEdit ? columns : columns.filter((c) => showColumns[c.id ?? ''] != false)}
        data={rows}
        allowEditColumn={allowEdit}
        showColumns={showColumns}
        columnOrders={columnOrders}
        allowResizeColumn={allowEdit}
        onResizeColumn={onResizeColumn}
        isFetching={loading}
        onShowColumnChange={onShowColumnChange}
        onColumnOrderChanged={onChangeColumnOrders}
        allowSort={false}
      />
      {allowEdit && (
        <div
          className={classNames(
            'absolute bottom-2 right-4 rounded-md bg-white opacity-50 hover:opacity-100',
            !resetOrderDisabed || isColumnWidthSet ? 'block' : 'hidden'
          )}
        >
          <PopupMenuButton
            onSelect={(key: string) => {
              switch (key) {
                case 'reset.column.order':
                  onChangeColumnOrders(undefined)
                  break
                case 'reset.all':
                  resetResizeColumn()
                  break
              }
            }}
            items={[
              [
                {
                  key: 'reset.column.order',
                  label: 'Reset Column Order',
                  disabled: resetOrderDisabed
                },
                {
                  key: 'reset.all',
                  label: 'Reset All'
                }
              ]
            ]}
            buttonIcon={<Cog6ToothIcon className="h-5 w-5" />}
          />
        </div>
      )}
    </>
  )
})

SqlTableChart.displayName = 'SqlTableChart'
