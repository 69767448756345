/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../fetch.pb"

export enum AiRole {
  AI_ROLE_SYSTEM = "AI_ROLE_SYSTEM",
  AI_ROLE_USER = "AI_ROLE_USER",
  AI_ROLE_ASSISTANT = "AI_ROLE_ASSISTANT",
}

export type AiMessage = {
  role?: AiRole
  content?: string
}

export type SQLCompletionRequest = {
  messages?: AiMessage[]
  projectOwner?: string
  projectSlug?: string
  version?: number
}

export type GraphQLCompletionRequest = {
  messages?: AiMessage[]
  projectOwner?: string
  projectSlug?: string
  version?: number
}

export type ChartCompletionRequest = {
  messages?: AiMessage[]
  projectOwner?: string
  projectSlug?: string
  version?: number
  dashboardId?: string
}

export type SQLCompletionResponse = {
  choices?: AiMessage[]
}

export type ChartCompletionResponse = {
  choices?: AiMessage[]
}

export type GraphQLCompletionResponse = {
  choices?: AiMessage[]
}

export class AiService {
  static SQLCompletion(req: SQLCompletionRequest, initReq?: fm.InitReq): Promise<SQLCompletionResponse> {
    return fm.fetchReq<SQLCompletionRequest, SQLCompletionResponse>(`/api/v1/ai/${req["projectOwner"]}/${req["projectSlug"]}/sql_completion`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GraphQLCompletion(req: GraphQLCompletionRequest, initReq?: fm.InitReq): Promise<GraphQLCompletionResponse> {
    return fm.fetchReq<GraphQLCompletionRequest, GraphQLCompletionResponse>(`/api/v1/ai/${req["projectOwner"]}/${req["projectSlug"]}/graphql_completion`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ChartCompletion(req: ChartCompletionRequest, initReq?: fm.InitReq): Promise<ChartCompletionResponse> {
    return fm.fetchReq<ChartCompletionRequest, ChartCompletionResponse>(`/api/v1/ai/${req["projectOwner"]}/${req["projectSlug"]}/chart_completion`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}