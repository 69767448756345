import useApi from './use-api'
import { useProjectVersions } from './use-project-versions'
import * as CommonCommon from 'gen/service/common/protos/common.pb'
import { dateTimeToString, DateTimeValue, durationToSeconds, timeBefore } from '../time'
import { Duration } from 'gen/service/common'
import { useProject } from './use-project'
import { Permission, Project } from '../../gen/service/common'
import { DataSource, InsightsService, QueryRequest, QueryRequestQuery } from '../../gen/service/insights'
import { expandArraySelectors, resolveVariables as resolveVariablesForAnalytics } from './use-analytics'
import { resolveVariables as resolveVariablesForMetrics } from './use-query-range'
import { useCallback, useMemo, useRef } from 'react'
import { calculateInterval } from '../interval'
import { withJsonApiAutoToken } from './with-json-api'
import { ChartConfigCompareTime } from '../../gen/service/web'

function resolveVariables(queries: QueryRequestQuery[], variablesValues: { [p: string]: string }): QueryRequestQuery[] {
  return queries.map((q) => {
    if (q.eventsQuery != null) {
      return {
        ...q,
        eventsQuery: resolveVariablesForAnalytics(expandArraySelectors([q.eventsQuery]), variablesValues)[0]
      }
    } else if (q.metricsQuery != null) {
      return { ...q, metricsQuery: resolveVariablesForMetrics([q.metricsQuery], variablesValues)[0] }
    } else {
      return q
    }
  })
}

export function makeInsightsRequest(
  projectOwner: string,
  projectSlug: string,
  chart: { insightsQueries?: QueryRequestQuery[]; formulas?: CommonCommon.Formula[] },
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  step: Duration | undefined,
  version?: number,
  tz?: string,
  limit = 20,
  offset = 0,
  variablesValues: { [p: string]: string } = {},
  cachePolicy?: CommonCommon.CachePolicy
) {
  const resolvedQueries = resolveVariables(chart.insightsQueries || [], variablesValues)
  const req: QueryRequest = {
    projectOwner,
    projectSlug,
    version,
    timeRange: {
      start: dateTimeToString(startTime),
      end: dateTimeToString(endTime),
      step: step ? durationToSeconds(step) : calculateInterval(startTime, endTime),
      timezone: tz
    },
    limit,
    queries: resolvedQueries.map((q) => ({ ...q })),
    formulas: chart.formulas,
    cachePolicy
  }
  return req
}

export function useQueryInsights(
  projectId: string | undefined | null,
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  tz: string,
  step: Duration | undefined,
  queries: QueryRequestQuery[],
  formulas: CommonCommon.Formula[],
  variablesValues: { [p: string]: string } = {},
  limit = 20,
  offset = 0,
  refreshInterval = 10000,
  fromShare?: { shareId: string; panelId: string; project?: Project },
  compare?: ChartConfigCompareTime,
  cachePolicy?: CommonCommon.CachePolicy,
  keepPreviousData = false
) {
  const { currentVersion } = useProjectVersions()
  const { owner, slug } = useProject([Permission.READ])
  const reqRef = useRef<any>(null)

  const req: QueryRequest = makeInsightsRequest(
    fromShare?.project?.ownerName ?? (owner as string),
    fromShare?.project?.slug ?? (slug as string),
    { insightsQueries: queries, formulas },
    startTime,
    endTime,
    step,
    currentVersion,
    tz,
    limit,
    offset,
    variablesValues,
    cachePolicy
  )

  const isReady = useMemo(() => {
    if (!projectId) {
      return false
    }
    for (const q of queries) {
      if (getInsightQuery(q)?.disabled) {
        continue
      }
      if (q?.metricsQuery?.query) {
        return true
      }
      if (q?.eventsQuery?.resource) {
        return true
      }
      if (q?.priceQuery?.coinId) {
        return true
      }
    }
    for (const f of formulas) {
      if (f?.expression && !f?.disabled) {
        return true
      }
    }

    return false
  }, [queries, formulas, projectId])
  const { data, loading, ready, error, mutate } = useApi(InsightsService.Query, isReady ? req : null, false, {
    refreshInterval,
    fromShare,
    keepPreviousData: keepPreviousData
  })

  const compareReq = useMemo(() => {
    if (compare?.ago) {
      const { ago } = compare
      if (ago) {
        const compareStartTime = timeBefore(startTime, ago, true)
        const compareEndTime = timeBefore(endTime, ago, false)
        return {
          ...req,
          timeRange: {
            ...req.timeRange,
            start: dateTimeToString(compareStartTime),
            end: dateTimeToString(compareEndTime)
          }
        }
      }
    }
    return null
  }, [compare, startTime, endTime, req])

  const { data: compareData } = useApi(InsightsService.Query, isReady ? compareReq : null, false, {
    refreshInterval,
    fromShare
  })

  reqRef.current = isReady ? req : null
  const bypassCacheMutate = useCallback(async () => {
    if (reqRef.current) {
      return mutate(() => {
        return withJsonApiAutoToken(InsightsService.Query)({
          ...reqRef.current,
          cachePolicy: {
            ...reqRef.current?.cachePolicy,
            noCache: true
          }
        })
      })
    }
  }, [mutate])

  return {
    data,
    compareData: compareReq ? compareData : undefined,
    error,
    loading: loading,
    ready,
    mutate,
    payload: req,
    bypassCacheMutate
  }
}

export function getInsightQuery(value: QueryRequestQuery) {
  switch (value.dataSource) {
    case DataSource.METRICS:
      return value?.metricsQuery
    case DataSource.EVENTS:
      return value?.eventsQuery
    case DataSource.PRICE:
      return value?.priceQuery
  }
}

export function useColinList(projectId?: string, query?: string) {
  const { owner, slug } = useProject([Permission.READ])
  const { currentVersion } = useProjectVersions()

  const { data, loading, ready, error } = useApi(
    InsightsService.ListCoins,
    projectId && {
      projectId,
      projectOwner: owner as string,
      projectSlug: slug as string,
      version: currentVersion,
      searchQuery: query
    }
  )
  return {
    coins: data?.coins || [],
    loading,
    ready,
    error
  }
}
