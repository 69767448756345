import { useState, createContext, useContext } from 'react'
import {
  useFloating,
  useHover,
  useInteractions,
  autoUpdate,
  flip,
  shift,
  safePolygon,
  Placement
} from '@floating-ui/react'
import { Menu } from '@headlessui/react'
import classNames from 'lib/classnames'
import { BiChevronRight } from 'react-icons/bi'
import { HiCheck } from 'react-icons/hi'
import { PopoverTooltip } from 'components/common/tooltip/PopoverTooltip'

export const COLOR_MAP = {
  default: {
    active: 'bg-gray-100 text-text-foreground dark:bg-primary-600 dark:text-white',
    default: 'text-text-foreground',
    disabled: 'text-gray-400 cursor-not-allowed'
  },
  danger: {
    active: 'bg-red-100 text-red-600 dark:bg-red-600 dark:text-white',
    default: 'text-red-600',
    disabled: 'text-red-200 dark:text-red-600/40 cursor-not-allowed'
  }
}

export const MenuContext = createContext<{ selectedKey?: string }>({})

type Props = IMenuItem & {
  items: IMenuItem[][]
  onSelect?: OnSelectMenuItem
  active: boolean
  name: string
  placement?: Placement
}

interface ItemProps {
  item: IMenuItem
  onSelect?: OnSelectMenuItem
  labelClassName?: string
}

export const MenuItem = ({ item, onSelect, labelClassName }: ItemProps) => {
  const { selectedKey } = useContext(MenuContext)
  return (
    <Menu.Item disabled={item.disabled}>
      {({ active }) => {
        if (item.items) {
          return (
            <SubMenuButton
              items={item.items}
              icon={item.icon}
              key={item.key}
              name={item.key}
              label={item.label}
              onSelect={onSelect}
              active={active}
            />
          )
        }
        const buttonNode = (
          <button
            onClick={(e) => onSelect?.(item.key, e, item)}
            className={classNames(
              item.disabled
                ? COLOR_MAP[item.status || 'default'].disabled
                : active
                  ? COLOR_MAP[item.status || 'default'].active
                  : COLOR_MAP[item.status || 'default'].default,
              'text-ilabel font-ilabel flex w-full items-center px-4 py-2'
            )}
            disabled={item.disabled}
          >
            {item.icon}
            <span className={classNames('flex-1 truncate text-left', labelClassName)}>{item.label}</span>
            {item.key === selectedKey ? <HiCheck className="icon-lg ml-2" /> : null}
          </button>
        )
        if (item.disabled && item.disabledHint) {
          return (
            <PopoverTooltip
              text={<span className="text-icontent font-icontent text-gray cursor-auto">{item.disabledHint}</span>}
              strategy="fixed"
            >
              {buttonNode}
            </PopoverTooltip>
          )
        }
        return buttonNode
      }}
    </Menu.Item>
  )
}

export const SubMenuButton = (props: Props) => {
  const { label, status, items, disabled, onSelect, active, placement = 'right-start' } = props
  const [open, setOpen] = useState(false)
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [flip(), shift()]
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon()
    })
  ])

  return (
    <Menu
      as="div"
      className={classNames(
        'group flex items-center',
        'rounded-md text-sm',
        disabled ? 'pointer-events-none cursor-not-allowed text-gray-400' : 'cursor-pointer'
      )}
    >
      <Menu.Button
        // aria-label={ariaLabel}
        className={classNames(
          active || open ? COLOR_MAP[status || 'default'].active : COLOR_MAP[status || 'default'].default,
          'flex w-full items-center px-4 py-2 text-sm'
        )}
        ref={reference}
        onClick={(e) => {
          e.preventDefault()
          onSelect && onSelect(props.name, e)
        }}
        {...getReferenceProps}
      >
        {props.icon}
        {/* <props.icon className={'mr-3 h-6 w-6 flex-shrink-0'} aria-hidden="true" /> */}
        <span className="flex-shrink flex-grow text-left">{label}</span>
        <BiChevronRight
          className={classNames(
            open ? 'text-gray-500' : 'text-gray-400',
            'h-4 w-4 flex-shrink-0  group-hover:text-gray-500',
            placement?.startsWith('bottom') ? 'rotate-90' : ''
          )}
          aria-label="expand items"
        />
      </Menu.Button>
      {open && (
        <Menu.Items
          static
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: 10
          }}
          className="dark:bg-sentio-gray-100 w-48 origin-top cursor-pointer divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-100"
          {...getFloatingProps}
        >
          {items.map((items, i) =>
            items && items.length > 0 ? (
              <div key={i} className="overflow-auto py-1" style={{ maxHeight: '60vh' }}>
                {items.map((item) => (
                  <MenuItem key={item.key} item={item} onSelect={onSelect} />
                ))}
              </div>
            ) : null
          )}
        </Menu.Items>
      )}
    </Menu>
  )
}
