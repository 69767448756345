import Head from 'next/head'

interface Props {
  name?: string
  description?: string
  url?: string
  imageUrl?: string
}

export function OpenGraphTags({ name, description, url, imageUrl }: Props) {
  const desc =
    description ||
    'Monitor. Analyze. Diagnose.\n' +
      'All In One Place.\n' +
      'End-to-end observability platform to help you gain insights, secure assets and troubleshoot transactions for your decentralized applications.'
  const title = name || 'Sentio'

  return (
    <Head>
      <meta name="twitter:card" content="summary_large_image" key="twitter_card"></meta>
      <meta name="twitter:title" content={title} key="title" />
      <meta name="twitter:site" content="@sentioxyz" />
      <meta name="twitter:description" content={desc} key="twitter_description" />
      {imageUrl && <meta name="twitter:image" content={imageUrl} key="twitter_image" />}

      <meta property="og:type" content="website" key="og_type" />
      <meta property="og:title" content={title} key="og_title" />
      <meta property="og:description" content={desc} key="og_description" />
      {url && <meta property="og:url" content={url} key="og_url" />}
      {imageUrl && <meta property="og:image" content={imageUrl} key="og_image" />}
    </Head>
  )
}
