import { forwardRef, useMemo } from 'react'
import { EChartsHandle } from '../EchartsBase'
import { TabularDataColumnType } from '../../../gen/service/common'
import { MetricsQueryResponse } from '../../../gen/service/observability'
import { SQLChartProps } from '../SqlChart'
import { ChartChartType } from '../../../gen/service/web'
import BarGaugeChart from '../BarGaugeChart'
import PieChart from '../PieChart'
import { QueryValueChart } from '../QueryValueChart'

export const SqlValuesChart = forwardRef<EChartsHandle, SQLChartProps>((props: SQLChartProps, ref) => {
  const { loading, config, chartType } = props

  const { error, data } = useMemo(() => {
    if (props.data && !loading) {
      const valueColumns: string[] = []
      const nameColumns: string[] = []
      for (const [column, type] of Object.entries(props.data?.result?.columnTypes || {})) {
        if (type === TabularDataColumnType.NUMBER) {
          valueColumns.push(column)
        } else if (type === TabularDataColumnType.STRING) {
          nameColumns.push(column)
        }
      }

      if (chartType !== ChartChartType.QUERY_VALUE) {
        if (nameColumns.length == 0) {
          return { error: 'Name column not found, please add a string column to the SELECT clause' }
        }
      }

      if (valueColumns.length == 0) {
        return { error: 'No numeric column is found, please add at least one numeric column to the SELECT clause' }
      }

      let rows = props.data?.result?.rows
      if (chartType === ChartChartType.QUERY_VALUE) {
        rows = [rows?.[0]]
      }
      const data = {
        results: [
          {
            matrix: {
              samples:
                rows && rows.length > 0
                  ? rows.map((row: undefined | Record<string, any>) => ({
                      metric: {
                        name: row?.[nameColumns[0]],
                        displayName: row?.[nameColumns[0]]
                      },
                      values: [
                        {
                          value: row?.[valueColumns[0]]
                        }
                      ]
                    }))
                  : []
            }
          }
        ]
      } as MetricsQueryResponse
      return { data }
    }
    return {}
  }, [props.data, loading, chartType])

  if (error) {
    return (
      <div className="flex h-full w-full items-center text-center">
        <p className="mx-auto text-sm">{error}</p>
      </div>
    )
  }

  switch (chartType) {
    case ChartChartType.BAR_GAUGE:
      return <BarGaugeChart {...props} data={data} ref={ref} />
    case ChartChartType.PIE:
      return <PieChart {...props} data={data} ref={ref} />
    case ChartChartType.QUERY_VALUE:
      return <QueryValueChart {...props} data={data} ref={ref} />
    default:
      return <div>Not Implemented</div>
  }
})

SqlValuesChart.displayName = 'SqlValuesChart'
