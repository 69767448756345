import { forwardRef, useEffect, useMemo, useState } from 'react'
import { EChartsHandle } from '../EchartsBase'
// import { TabularDataColumnType } from '../../../gen/service/common'
import { MetricsQueryResponse } from '../../../gen/service/observability'
import TimeSeriesChart from '../TimeSeriesChart'
import { SQLChartProps } from '../SqlChart'
// import dayjs from 'dayjs'
import { useGlobalWorker } from 'lib/worker/use-global-worker'
import { eventName } from 'lib/worker/parsers/SqlTimeSeriesChartParser'

export const SqlTimeSeriesChart = forwardRef<EChartsHandle, SQLChartProps>((props: SQLChartProps, ref) => {
  const { loading, config, chartType, error: sqlError } = props
  const { addListener, postMessage } = useGlobalWorker()
  const [error, setError] = useState<string | undefined>()
  const [data, setData] = useState<MetricsQueryResponse | undefined>()

  useEffect(() => {
    addListener(eventName, (res) => {
      const { error, data } = res
      setError(error)
      setData(data)
    })
  }, [])
  useEffect(() => {
    if (!props.data) return
    postMessage(eventName, {
      sqlError,
      data: props.data,
      loading,
      chartType
    })
  }, [props.data, loading, chartType, sqlError])

  if (error) {
    return (
      <div className="flex h-full w-full items-center text-center">
        <p className="mx-auto text-sm">{error}</p>
      </div>
    )
  }

  return <TimeSeriesChart {...props} data={data} ref={ref} />
})

SqlTimeSeriesChart.displayName = 'SqlTimeSeriesChart'
