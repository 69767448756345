import { ChartProps } from './Chart'
import { forwardRef, useCallback, useMemo } from 'react'
import { defaults, isNull, omitBy } from 'lodash'
import { defaultConfig as valueDefault } from './options/ValueOptions'
import { defaultConfig as tableDefault } from './options/TableControls'
import { produce } from 'immer'
import { getTableData } from 'lib/metrics/table'
import DataGrid from '../common/table/DataGrid'
import { TableState, useTableState } from 'lib/data/use-table-state'
import { ColumnDef } from '@tanstack/react-table'
import { EChartsHandle } from './EchartsBase'

const defaultConfig = {
  valueConfig: valueDefault,
  tableConfig: tableDefault
}

const TableChart = forwardRef<EChartsHandle, ChartProps>(
  ({ data, onChangeConfig, config, allowEdit, panelId, loading }: ChartProps, ref) => {
    config = defaults(omitBy(config, isNull), defaultConfig)

    const { rows, columns, sortBy } = useMemo(() => {
      const { columns, rows } = getTableData(data, config)
      let filteredColumns: ColumnDef<any>[] = columns.map((c) => ({
        ...c,
        sortUndefined: 1
      }))
      const showColumns = { ...(config?.tableConfig?.showColumns || {}) }
      if (Object.keys(showColumns).length == 0) {
        columns.forEach((c) => (showColumns[c.id!] = true))
      }
      if (!allowEdit) {
        filteredColumns = filteredColumns.filter((c) => showColumns[c.id!] !== false)
      }
      const sortBy = (config?.tableConfig?.sortColumns || [])
        .filter((c) => {
          return filteredColumns.some((d) => c.column == d.id)
        })
        .map((c) => ({ id: c.column!, desc: c.orderDesc! }))

      if (sortBy.length == 0 && filteredColumns.length > 0) {
        sortBy.push({ id: filteredColumns[0].id!, desc: false })
      }

      return { rows, columns: filteredColumns, sortBy }
    }, [data, config, allowEdit])

    const onChangeTableState = useCallback(
      (state: TableState) => {
        onChangeConfig &&
          config &&
          onChangeConfig(
            produce(config, (draft) => {
              const tableConfig = draft.tableConfig || (draft.tableConfig = {})
              tableConfig.showColumns = state.showColumns
              tableConfig.sortColumns = state.sorting.map((s) => ({
                column: s.id,
                orderDesc: s.desc
              }))
              tableConfig.columnOrders = state.columnOrders
            })
          )
      },
      [config, onChangeConfig]
    )

    const { sortingState, columnOrders, showColumns, onChangeSorting, onChangeColumnOrders, onShowColumnChange } =
      useTableState(
        panelId || '',
        columns,
        {
          sorting: sortBy,
          columnOrders: config?.tableConfig?.columnOrders || [],
          showColumns: config?.tableConfig?.showColumns || {}
        },
        onChangeTableState
      )

    function onResizeColumn(columWidths: { [col: string]: number | null }) {
      if (onChangeConfig && config) {
        onChangeConfig(
          produce(config, (draft) => {
            Object.assign(draft.tableConfig?.columnWidths || {}, columWidths)
          })
        )
      }
    }

    return (
      <div className="h-full w-full overflow-auto">
        <DataGrid
          columns={columns}
          data={rows}
          allowEditColumn={allowEdit}
          showColumns={showColumns}
          sortings={sortingState}
          onShowColumnChange={onShowColumnChange}
          onSortChange={onChangeSorting}
          onResizeColumn={onResizeColumn}
          allowSort
          allowResizeColumn={allowEdit}
          columnOrders={columnOrders}
          onColumnOrderChanged={onChangeColumnOrders}
          isFetching={loading}
        />
      </div>
    )
  }
)

TableChart.displayName = 'TableChart'
export default TableChart
