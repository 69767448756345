/**
 * The differece between this and the PopoverTooltip.tsx is that this one pass mouse click event to the parent
 */
import React, { useRef, useState } from 'react'
import {
  useFloating,
  useHover,
  useInteractions,
  safePolygon,
  arrow,
  offset,
  shift,
  Strategy,
  flip,
  useDelayGroupContext,
  autoUpdate,
  FloatingPortal
} from '@floating-ui/react'
import { OffsetOptions, Placement } from '@floating-ui/core'
import classNames from 'lib/classnames'
import { isString } from 'lodash'
import { useDetectExtenstion } from 'lib/extension/context'

interface Props {
  text?: string | React.ReactNode
  className?: string
  buttonClassName?: string
  icon?: React.ReactNode
  children?: React.ReactNode
  strategy?: Strategy
  hideArrow?: boolean
  offsetOptions?: OffsetOptions
  placementOption?: Placement
  maxWidth?: string
  usePortal?: boolean
}

export const PopoverTooltip = ({
  icon,
  text,
  className,
  buttonClassName,
  children,
  strategy: propStrategy,
  hideArrow,
  offsetOptions = 8,
  placementOption = 'bottom',
  maxWidth = 'max-w-[300px]',
  usePortal = false
}: Props) => {
  const arrowRef = useRef(null)
  const [open, setOpen] = useState(false)
  const {
    delay = {
      open: 500,
      close: 0
    }
  } = useDelayGroupContext()
  const {
    x,
    y,
    reference,
    floating,
    strategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    context,
    placement
  } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(offsetOptions), flip(), shift(), arrow({ element: arrowRef, padding: 8 })],
    strategy: propStrategy,
    placement: placementOption,
    whileElementsMounted: autoUpdate
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon({
        buffer: -Infinity
      }),
      delay: delay
    })
  ])

  const isExtension = useDetectExtenstion()

  if (!text)
    return (
      <>
        {icon}
        {children}
      </>
    )

  const Portal = usePortal ? FloatingPortal : React.Fragment

  return (
    <div className={classNames('relative flex items-center', className)}>
      <div ref={reference} {...getReferenceProps} className={buttonClassName}>
        {icon}
        {children}
      </div>

      {open && (
        <Portal>
          <div className={isExtension ? '_sentio_' : ''}>
            <div
              className="sentio-tooltip dark:bg-sentio-gray-200 z-10 rounded-md bg-white p-2 text-xs shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-gray-100"
              ref={floating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0
              }}
              {...getFloatingProps}
            >
              {!hideArrow && placement === 'bottom' && (
                <div
                  className="arrow dark:bg-sentio-gray-100 before:border-border-color -translate-y-[7px] bg-white before:visible before:border before:border-b-0 before:border-r-0"
                  ref={arrowRef}
                  style={{
                    left: arrowX ?? 0,
                    top: arrowY ?? 0,
                    position: 'absolute'
                  }}
                />
              )}
              {isString(text) ? (
                <pre className={classNames('w-max whitespace-pre-wrap', maxWidth)}>{text}</pre>
              ) : (
                <div className={classNames('w-max max-w-[300px] overflow-auto', maxWidth)}>{text}</div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}
