import useApi from './use-api'
import {
  AnalyticService,
  ExecutionInfo,
  ExecutionStatus,
  QueryTablesRequest,
  Source,
  SQLGrammarVersion,
  SyncExecuteSQLRequest,
  SyncExecuteSQLResponse
} from '../../gen/service/analytic'
import { AiMessage, AiRole, AiService, SQLCompletionRequest } from '../../gen/service/ai'
import { Permission, Project, TabularData } from '../../gen/service/common'
import { useProjectVersions } from './use-project-versions'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import withJsonApi, { withJsonApiAutoToken } from './with-json-api'
import { useLocalStorage } from './use-local-storage'
import { minBy } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useProject } from './use-project'
import SqlString from 'sqlstring'
import { DateTimeValue, isNow, isRelativeTime, RelativeTime, toDayjs } from '../time'
import * as CommonCommon from '../../gen/service/common/protos/common.pb'

export function useSchema(project?: Project) {
  const { currentVersion } = useProjectVersions()
  const req =
    project &&
    ({
      projectId: project.id,
      projectSlug: project.slug,
      projectOwner: project.ownerName,
      version: currentVersion
    } as QueryTablesRequest)
  const { data, loading, error } = useApi(AnalyticService.QueryTables, req)

  return {
    schema: data,
    loading,
    error
  }
}

export interface RecentQuery {
  id: number
  name: string
  sql: string
  serverCost?: string
  status: 'success' | 'error'
  error?: string
  time: number
  results: any
}

export function makeSQLPayload(
  projectOwner: string,
  projectSlug: string,
  chart: { sqlQuery?: string },
  version?: number,
  variables: Record<string, string> = {},
  source?: Source,
  cachePolicy?: CommonCommon.CachePolicy
) {
  const { sql, size } = JSON.parse(chart.sqlQuery || '{}')
  return {
    projectSlug,
    projectOwner,
    version,
    sqlQuery: {
      sql: resolveSQLQuery(sql, variables),
      variables,
      size
    },
    source: source ?? Source.DASHBOARD,
    cachePolicy
  } as SyncExecuteSQLRequest
}

export function resolveSQLQuery(sql: string, variables: Record<string, string>) {
  return sql.replace(/\$([a-zA-Z0-9_]+)/g, (match, name) => {
    const value = variables[name]
    if (value) {
      if (name === 'startTime' || name === 'endTime') {
        return value
      }
      return SqlString.escape(value)
    }
    return `''`
  })
}

function sqlDateTime(value: DateTimeValue, tz?: string) {
  if (isRelativeTime(value)) {
    const rd = value as RelativeTime
    if (!rd.align) {
      if (isNow(rd)) {
        return 'now()'
      }
      const unit = rd.unit?.slice(0, -1) ?? 'second'
      return `(now() ${rd.sign < 0 ? '-' : '+'} interval ${rd.value} ${unit})`
    }
  }

  const s = toDayjs(value).format('YYYY-MM-DD HH:mm:ss')
  if (tz) {
    return SqlString.format(`toDateTime(?, ?)`, [s, tz])
  }
  return SqlString.format(`toDateTime(?)`, [s])
}

export function useSQLQuery(
  projectId: string | undefined,
  sql: string | undefined,
  size = 100,
  variables: Record<string, string> = {},
  fromShare?: { shareId: string; panelId: string; project?: Project },
  startTime?: DateTimeValue,
  endTime?: DateTimeValue,
  tz?: string,
  source?: Source,
  cachePolicy?: CommonCommon.CachePolicy
) {
  const { currentVersion } = useProjectVersions()
  const { owner, slug } = useProject([Permission.READ])

  const request = useMemo(() => {
    if (!projectId || !sql) {
      return undefined
    }

    if (startTime) {
      variables.startTime = sqlDateTime(startTime, tz)
    }
    if (endTime) {
      variables.endTime = sqlDateTime(endTime, tz)
    }

    const projectOwner = fromShare?.project?.ownerName ?? (owner as string)
    const projectSlug = fromShare?.project?.slug ?? (slug as string)
    return makeSQLPayload(
      projectOwner,
      projectSlug,
      { sqlQuery: JSON.stringify({ sql, size }) },
      currentVersion,
      variables,
      source,
      cachePolicy
    )
  }, [projectId, sql, size, currentVersion, variables, startTime, endTime, tz, source])
  const reqRef = useRef(request)
  reqRef.current = request

  const { data, loading, error, fetching, mutate } = useApi(AnalyticService.ExecuteSQL, request, false, {
    fromShare,
    shouldRetryOnError: false
  })

  const bypassCacheMutate = useCallback(async () => {
    if (reqRef.current) {
      return mutate(() => {
        return withJsonApiAutoToken(AnalyticService.ExecuteSQL, false, { fromShare })({
          ...reqRef.current,
          cachePolicy: {
            ...reqRef.current?.cachePolicy,
            noCache: true
          }
        })
      })
    }
  }, [mutate, fromShare])

  return {
    data,
    loading: fetching,
    error: error ?? data?.error,
    payload: request,
    bypassCacheMutate
  }
}

export function useSQL(project?: Project, saveRecent?: boolean) {
  const { currentVersion } = useProjectVersions()
  const request = project && {
    projectId: project.id,
    projectSlug: project.slug,
    projectOwner: project.ownerName,
    version: currentVersion
  }
  const [data, setData] = useState<SyncExecuteSQLResponse>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [timer, setTimer] = useState<[number, number]>()
  const { recentQueries, saveRecentQuery } = useRecentQueries(project)

  const abortControllerRef = useRef<AbortController>()

  const executeSQL = async (
    sql: string,
    size = 100,
    version?: SQLGrammarVersion,
    variables: Record<string, string> = {},
    startTime?: DateTimeValue,
    endTime?: DateTimeValue,
    tz?: string,
    source: Source = Source.SQL_EDITOR
  ) => {
    if (startTime) {
      variables.startTime = sqlDateTime(startTime, tz)
    }
    if (endTime) {
      variables.endTime = sqlDateTime(endTime, tz)
    }
    const req = {
      ...request,
      sqlQuery: {
        sql: resolveSQLQuery(sql, variables),
        size,
        version
      },
      source,
      cachePolicy: {
        noCache: source === Source.SQL_EDITOR,
        cacheTtlSecs: startTime && endTime && Math.floor(toDayjs(endTime).diff(toDayjs(startTime), 'seconds') / 2)
      }
    } as SyncExecuteSQLRequest
    setLoading(true)
    setData(undefined)
    setError(undefined)
    const start = Date.now()
    setTimer([start, 0])
    const f = withJsonApi(AnalyticService.ExecuteSQL)
    try {
      const abortController = new AbortController()
      abortControllerRef.current = abortController
      const data = await f(req, { signal: abortController.signal })
      setData(data)
      if (saveRecent) {
        saveRecentQuery(sql, data)
      }
      if (data.error) {
        setError(data.error)
      }
      return data
    } catch (e: any) {
      if (e?.body?.message) {
        setError(e?.body?.message)
      } else {
        setError(e?.statusText)
      }
      if (saveRecent) {
        saveRecentQuery(sql, null, e?.body?.message)
      }
      throw e
    } finally {
      setLoading(false)
      setTimer([start, Date.now()])
    }
  }

  const cancelExecuteSQL = () => {
    if (loading) {
      setLoading(false)
      setTimer([timer?.[0] || 0, Date.now()])
      abortControllerRef.current?.abort()
    }
  }

  return {
    executeSQL,
    cancelExecuteSQL,
    data,
    error,
    clearError: () => setError(undefined),
    loading,
    executeTimer: timer,
    recentQueries
  }
}

const MAX_RECENT_QUERIES = 20

export function useRecentQueries(project?: Project) {
  const [recentQueries, setRecentQueries] = useLocalStorage<Record<number, RecentQuery>>(
    project && `sql-tabs.${project.id}.recent_queries`,
    {},
    true
  )

  const saveRecentQuery = (sql: string, data: SyncExecuteSQLResponse | null, error?: string) => {
    const exists = Object.values(recentQueries).find((q) => q.sql === sql)
    if (exists) {
      exists.time = new Date().getTime()
      exists.error = data?.error || error
      exists.status = data?.error || error ? 'error' : 'success'
      if (data) {
        exists.serverCost = data.runtimeCost
      }
    } else {
      const id = Math.max(...Object.keys(recentQueries).map((k) => parseInt(k)), 0) + 1
      const newQuery = {
        id,
        name: `History ${id}`,
        sql,
        status: data?.error || error ? 'success' : 'error',
        error: data?.error || error,
        time: new Date().getTime()
      } as RecentQuery
      if (data) {
        newQuery.serverCost = data.runtimeCost
        newQuery.results = data
      }
      if (Object.keys(recentQueries).length >= MAX_RECENT_QUERIES) {
        // delete the oldest query
        const oldest = minBy(Object.values(recentQueries), (q) => q.time)
        if (oldest) {
          delete recentQueries[oldest.id]
        }
      }
      recentQueries[id] = newQuery
    }
    setRecentQueries({ ...recentQueries })
  }

  const clearRecentQueries = () => {
    setRecentQueries([])
  }

  const sortedQueries = useMemo(() => Object.values(recentQueries).sort((a, b) => b.time - a.time), [recentQueries])

  return {
    recentQueries: sortedQueries,
    saveRecentQuery,
    clearRecentQueries
  }
}

export function useAIAssistant(project: Project, version?: string) {
  const [loading, setLoading] = useState(false)
  const { currentVersion } = useProjectVersions()
  const abortControllerRef = useRef<AbortController>()

  const cancel = () => {
    if (loading) {
      setLoading(false)
      abortControllerRef.current?.abort()
    }
  }

  async function sqlComplete(query: string, history: AiMessage[]) {
    mixpanel.time_event('AI Generate SQL')

    const f = withJsonApi(AiService.SQLCompletion)
    const req: SQLCompletionRequest = {
      projectOwner: project.ownerName,
      projectSlug: project.slug,
      version: currentVersion,
      messages: [
        ...history,
        {
          content: query,
          role: AiRole.AI_ROLE_USER
        }
      ]
    }
    if (loading) {
      return
    }
    setLoading(true)
    try {
      const abortController = new AbortController()
      abortControllerRef.current = abortController
      const resp = await f(req, { signal: abortController.signal })
      const sql = resp?.choices?.[0]?.content
      if (sql) {
        if (sql) {
          mixpanel.track('AI Generate SQL', {
            success: true,
            query: query,
            sql: sql
          })
          return sql
        }
      }
      mixpanel.track('AI Generate SQL', {
        success: false,
        query: query
      })
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    sqlComplete,
    cancel
  }
}

export function useAsyncSQL(project: Project, id?: { queryId?: string; executionId?: string }) {
  const { currentVersion } = useProjectVersions()
  const request = project && {
    projectId: project.id,
    projectSlug: project.slug,
    projectOwner: project.ownerName,
    version: currentVersion
  }
  const [timer, setTimer] = useState<[number, number]>()
  const [data, setData] = useState<TabularData>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [execution, setExecution] = useState<ExecutionInfo | undefined>(undefined)
  const [runCount, setRunCount] = useState(0)
  const intervalWindow = useMemo(() => {
    if (runCount < 5) {
      return 2000
    }
    return 10000
  }, [runCount])
  useEffect(() => {
    setRunCount(0)
  }, [id?.queryId, id?.executionId])

  const abortControllerRef = useRef<AbortController>()

  const { data: resultData, error: resultError } = useApi(
    AnalyticService.QuerySQLResult,
    id && {
      ...request,
      executionId: id.executionId
    },
    false,
    {
      refreshInterval: data || error ? 0 : intervalWindow
    }
  )

  useEffect(() => {
    setRunCount((c) => c + 1)
  }, [resultData])

  useEffect(() => {
    if (resultError) {
      setError(resultError)
    } else if (resultData?.executionInfo) {
      setExecution(resultData.executionInfo)
    }
  }, [resultData, resultError])

  const executeSQL = async (
    sql: string,
    size = 100,
    version?: SQLGrammarVersion,
    variables: Record<string, string> = {},
    startTime?: DateTimeValue,
    endTime?: DateTimeValue,
    tz?: string,
    source: Source = Source.SQL_EDITOR
  ) => {
    if (startTime) {
      variables.startTime = sqlDateTime(startTime, tz)
    }
    if (endTime) {
      variables.endTime = sqlDateTime(endTime, tz)
    }
    const req = {
      ...request,
      sqlQuery: {
        sql: resolveSQLQuery(sql, variables),
        size,
        version
      }
    }
    setLoading(true)
    setData(undefined)
    setError(undefined)
    const start = Date.now()
    setTimer([start, 0])

    try {
      const abortController = new AbortController()
      abortControllerRef.current = abortController
      let executeInfo: ExecutionInfo | undefined
      if (!id) {
        const f = withJsonApi(AnalyticService.SaveAndRunSQL)
        const data = await f(
          {
            ...req,
            source
          },
          { signal: abortController.signal }
        )
        executeInfo = data.executionInfo
      } else {
        const f = withJsonApi(AnalyticService.RerunSQLQuery)
        const data = await f(
          {
            ...req,
            queryId: id.queryId
          },
          { signal: abortController.signal }
        )
        executeInfo = data.executionInfo
      }
      setExecution(execution)

      return { queryId: executeInfo?.queryId, executionId: executeInfo?.executionId }
    } catch (e: any) {
      if (e?.body?.message) {
        setError(e?.body?.message)
      } else {
        setError(e?.statusText)
      }

      throw e
    } finally {
      setLoading(false)
      setTimer([start, Date.now()])
    }
  }

  useEffect(() => {
    if (execution?.result) {
      setData(execution.result)
    }
    if (execution?.error) {
      setError(execution.error)
    }
    const started = execution?.startedAt ? new Date(execution.startedAt).getTime() : 0
    const ended = execution?.finishedAt ? new Date(execution.finishedAt).getTime() : 0

    switch (execution?.status) {
      case ExecutionStatus.RUNNING:
        setTimer([started, 0])
        break
      case ExecutionStatus.FINISHED:
        setTimer([started, ended])
        break
      default:
        setTimer(undefined)
        break
    }
  }, [execution])

  const runtimeCost = useMemo(() => {
    if (execution?.computeStats?.computeCostMs) {
      return parseInt(execution.computeStats.computeCostMs)
    }
    return -1
  }, [execution])

  const cancelExecuteSQL = useCallback(() => {
    abortControllerRef.current?.abort()
    setLoading(false)
    setTimer(undefined)
  }, [loading])

  return {
    data,
    loading,
    error,
    executeSQL,
    executeTimer: timer,
    status: execution?.status,
    runtimeCost,
    cancelExecuteSQL
  }
}

export function useSQLQueries(project?: Project) {
  const { currentVersion } = useProjectVersions()
  const { data, loading, error } = useApi(
    AnalyticService.ListSQLQueries,
    project && {
      projectId: project.id,
      projectSlug: project.slug,
      projectOwner: project.ownerName,
      version: currentVersion,
      includeExecutionResult: true,
      sources: [Source.SQL_EDITOR]
    }
  )

  return {
    queries: data,
    loading,
    error
  }
}

export function deleteQueries(project: Project, queryIds: string[]) {
  const f = withJsonApi(AnalyticService.DeleteSQLQuery)
  const promises = queryIds.map((queryId) =>
    f({
      projectId: project.id,
      projectSlug: project.slug,
      projectOwner: project.ownerName,
      queryId
    })
  )
  return Promise.all(promises)
}
