import { MetricInfo } from 'gen/service/observability'
import { getChainName } from '@sentio/chain'

export const SystemLabels = [
  {
    field: 'contract_name',
    name: 'contract',
    getValues(metric: MetricInfo) {
      return (metric.contractName || []).map((name) => ({ value: name, display: name }))
    },
  },
  {
    field: 'contract_address',
    name: 'address',
    getValues(metric: MetricInfo) {
      return (metric.contractAddress || []).map((name) => ({
        value: name,
        display: name,
      }))
    },
  },
  {
    field: 'chain',
    name: 'chain',
    getValues(metric: MetricInfo) {
      return (metric.chainId || []).map((chainId) => {
        return { value: chainId, display: getChainName(chainId) }
      })
    },
  },
]
