import { SyncExecuteSQLResponse } from '@sentio/service/analytic'
import { TabularDataColumnType } from '@sentio/service/common'
import { MetricsQueryResponse } from '@sentio/service/observability'
import { ChartChartType } from '@sentio/service/web'
import dayjs from 'dayjs'

export const eventName = 'SQLTimeSeriesChartGetData'
export const parser = (rawData: {
  sqlError?: string
  data?: SyncExecuteSQLResponse
  loading: boolean
  chartType: ChartChartType
}) => {
  const { sqlError, data, loading, chartType } = rawData
  if (sqlError) {
    return { error: sqlError }
  }
  if (data && !loading) {
    const timeColumns: string[] = []
    const valueColumns: string[] = []
    const labelColumns: string[] = []
    for (const [column, type] of Object.entries(data?.result?.columnTypes || {})) {
      if (type === TabularDataColumnType.NUMBER) {
        valueColumns.push(column)
      } else if (type === TabularDataColumnType.TIME) {
        timeColumns.push(column)
      } else if (type === TabularDataColumnType.STRING) {
        // try if this can be parsed as time
        try {
          if (dayjs(data?.result?.rows?.[0]?.[column]).isValid()) {
            timeColumns.push(column)
          } else {
            labelColumns.push(column)
          }
        } catch (e) {
          // if not, then use it as a label column
          labelColumns.push(column)
        }
      }
    }

    if (timeColumns.length == 0) {
      return { error: 'Time column not found, please add a timestamp column to the SELECT clause' }
    }
    if (valueColumns.length == 0) {
      return { error: 'No numeric column is found, please add at least one numeric column to the SELECT clause' }
    }
    const series = {}
    for (const valueColumn of valueColumns) {
      const name = valueColumn
      let displayName = name
      for (const row of data?.result?.rows || []) {
        const labels: Record<string, any> = labelColumns.reduce((acc, labelColumn) => {
          acc[labelColumn] = row[labelColumn]
          return acc
        }, {})
        if (labels['alias']) {
          displayName = labels['alias']
        }
        const key = `${name}{${Object.entries(labels)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .map(([k, v]) => `${k}="${v}"`)
          .join(',')}}`
        const s = series[key] || {
          metric: {
            name,
            displayName,
            labels
          },
          values: []
        }
        s.values.push({
          timestamp: `${dayjs(row[timeColumns[0]]).unix()}`,
          value: row[name]
        })
        series[key] = s
      }
    }
    return {
      data: {
        results: [
          {
            matrix: {
              samples: Object.values(series).map((s: any) => ({
                ...s,
                values: s.values.sort((a, b) => a.timestamp - b.timestamp)
              }))
            }
          }
        ]
      } as MetricsQueryResponse
    }
  }
  return {}
}
