import { useDarkMode, useSetDarkMode } from 'lib/util/use-dark-mode'
import { MdLightMode, MdDarkMode } from 'react-icons/md'
import classNames from 'lib/classnames'
import { useMemo } from 'react'
import ButtonGroup from '../buttons/NewButtonGroup'
import { PopoverTooltip } from '../tooltip/DivTooltip'
import { FloatingDelayGroup } from '@floating-ui/react'
import mixpanel from 'mixpanel-browser'

export const ThemeSwitch = () => {
  const isDarkMode = useDarkMode()
  const { value, onChange } = useSetDarkMode()
  const hint = useMemo(() => {
    if (value === 'system') {
      return 'system theme' + (isDarkMode ? ' (dark)' : ' (light)')
    }
    return isDarkMode ? 'dark mode' : 'light mode'
  }, [isDarkMode, value])

  return (
    <div
      className={classNames(
        'relative inline-block cursor-pointer rounded-full p-1.5',
        isDarkMode
          ? 'hover:bg-text-background border-inherit text-gray-300 active:bg-gray-200/50'
          : 'border-primary-300 active:bg-primary-200/50 hover:text-primary-600 hover:bg-primary-200/50 text-gray-500 opacity-50 hover:opacity-100'
      )}
      onClick={() => {
        if (value === 'system') {
          onChange('light')
        } else if (value === 'light') {
          onChange('dark')
        } else {
          onChange('system')
        }
      }}
      title={`Current theme: ${hint}, click to toggle`}
    >
      {isDarkMode ? <MdDarkMode className="h-5 w-5" /> : <MdLightMode className="h-5 w-5" />}
      {value === 'system' ? <div className="absolute bottom-0.5 right-0.5 text-[10px] font-semibold">A</div> : null}
    </div>
  )
}

export const ThemeGroupButton = () => {
  const { value, onChange: setDarkMode } = useSetDarkMode()
  return (
    <div className="space-y-2 px-4 py-2">
      <h3 className="text-text-foreground-secondary text-xs">APPEARANCE</h3>
      <div className="flex items-center gap-2">
        <label className="text-text-foreground text-xs">Theme</label>
        <ButtonGroup
          value={value as any}
          small
          onChange={(value) => {
            mixpanel.track('Change Theme', { theme: value })
            setDarkMode(value)
          }}
          buttons={[
            {
              label: 'Light',
              value: 'light'
            },
            {
              label: 'Dark',
              value: 'dark'
            },
            {
              label: (
                <FloatingDelayGroup
                  delay={{
                    open: 1500,
                    close: 200
                  }}
                >
                  <PopoverTooltip
                    text={<p className="text-text-foreground-secondary">{`Follow your system's theme settings`}</p>}
                  >
                    System
                  </PopoverTooltip>
                </FloatingDelayGroup>
              ),
              value: 'system'
            }
          ]}
          buttonClassName="!px-2 !py-1"
        />
      </div>
    </div>
  )
}
