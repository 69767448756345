import React from 'react'
import useUser from 'lib/data/use-user'
import { useAuth0 } from '@auth0/auth0-react'

export const targetLink = 'https://www.sentio.xyz/pricing'

export const MultiVersionHint = (
  <span>
    Free tier only supports one version.{' '}
    <a href={targetLink} target="_blank" rel="noreferrer" className="mr-1 text-primary">
      Upgrade
    </a>
    to unlock this feature
  </span>
)

export const ExportCurlLinkHint = () => {
  const { user } = useUser()
  const { loginWithRedirect } = useAuth0()
  if (!user) {
    return (
      <div>
        <div>
          Anonymous user cannot export curl link.
        </div>
        <a className="text-primary hover:underline cursor-pointer" onClick={(evt) => {
          evt.preventDefault()
          loginWithRedirect()
        }}>
          Please login to get full access.
        </a>
      </div>
    )
  }
  return (
    <span>
      Free tier cannot export curl link.{' '}
      <a href={targetLink} target="_blank" rel="noreferrer" className="mr-1 text-primary">
        Upgrade
      </a>
      to unlock this feature
    </span>
  )
}

export const AlertsNumberHint = (
  <span>
    <a href={targetLink} target="_blank" rel="noreferrer" className="mr-1 text-primary">
      Upgrade
    </a>
    to add more alerts
  </span>
)

export const ProjectsNumberHintNode = (
  <span>
    <a href={targetLink} target="_blank" rel="noreferrer" className="mr-1 text-primary">
      Upgrade
    </a>
    to create more projects
  </span>
)
export const ProjectsNumberHint = 'Upgrade to create more projects'
