import { CSSProperties, forwardRef, memo } from 'react'
import { DateTimeValue } from 'lib/time'
import { MetricsQueryResponse } from 'gen/service/observability'
import { ChartChartType, ChartConfig, ChartDataSourceType, Chart } from 'gen/service/web'
import TimeSeriesChart from './TimeSeriesChart'
import BarGaugeChart from './BarGaugeChart'
import TableChart from './TableChart'
import { QueryValueChart } from './QueryValueChart'
import PieChart from './PieChart'
import type { ECharts } from 'echarts/core'
import { EChartsHandle } from './EchartsBase'
import { Provider } from 'jotai/react'

interface Mark {
  value?: number
  label?: string
  above?: boolean
  below?: boolean
  color?: string
  from?: Date
  to?: Date
}

export type MarkLine = Mark
export type MarkArea = Omit<Mark, 'above' | 'below' | 'value'>

export interface ChartProps {
  group?: string
  data?: MetricsQueryResponse
  compareData?: MetricsQueryResponse
  markAreas?: MarkArea[]
  markLines?: MarkLine[]
  startTime?: DateTimeValue
  endTime?: DateTimeValue
  tz?: string
  minHeight?: number
  onSelectTimeRange?: (start: DateTimeValue, end: DateTimeValue) => void
  title?: string
  loading?: boolean
  controls?: boolean
  config?: ChartConfig
  onChangeConfig?: (config: ChartConfig) => void
  style?: CSSProperties
  chartType?: ChartChartType
  allowEdit?: boolean
  panelId?: string
  showSymbol?: boolean
  allowClick?: boolean
  sourceType?: ChartDataSourceType
  getEventNameById?: (id?: string) => string
  noLegend?: boolean
  onInitChart?: (chart: ECharts) => void
  chartDataRef?: React.MutableRefObject<Chart>
}

const Chart = forwardRef<EChartsHandle, ChartProps>((props: ChartProps, ref) => {
  switch (props.chartType) {
    case ChartChartType.TABLE:
      return (
        <Provider>
          <TableChart {...props} ref={ref} />
        </Provider>
      )
    case ChartChartType.BAR_GAUGE:
      return <BarGaugeChart {...props} ref={ref} />
    case ChartChartType.QUERY_VALUE:
      return <QueryValueChart {...props} ref={ref} />
    case ChartChartType.PIE:
      return <PieChart {...props} ref={ref} />
    default:
      return <TimeSeriesChart {...props} ref={ref} />
  }
})
Chart.displayName = 'Chart'
Chart.defaultProps = {
  minHeight: 270
}

export default memo(Chart)
