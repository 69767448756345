import { forwardRef } from 'react'
import { ChartProps } from './Chart'
import { ChartChartType } from 'gen/service/web'
import { EChartsHandle } from './EchartsBase'
import { SyncExecuteSQLResponse } from '../../gen/service/analytic'

import { SqlTableChart } from './sqlcharts/SqlTableChart'
import { SqlTimeSeriesChart } from './sqlcharts/SqlTimeSeriesChart'
import { SqlValuesChart } from './sqlcharts/SqlValuesChart'
import { Provider } from 'jotai/react'

export interface SQLChartProps extends Omit<ChartProps, 'data'> {
  data?: SyncExecuteSQLResponse
  error?: string
}

const SqlChart = forwardRef<EChartsHandle, SQLChartProps>((props: SQLChartProps, ref) => {
  const { chartType, loading } = props

  switch (chartType) {
    case ChartChartType.TABLE:
      return (
        <Provider>
          <SqlTableChart {...props} ref={ref} />
        </Provider>
      )
    case ChartChartType.LINE:
    case ChartChartType.AREA:
    case ChartChartType.BAR:
      return <SqlTimeSeriesChart {...props} ref={ref} />
    case ChartChartType.BAR_GAUGE:
    case ChartChartType.QUERY_VALUE:
    case ChartChartType.PIE:
      return <SqlValuesChart {...props} ref={ref} />
    default:
      return <>Not Implemented</>
  }
})

SqlChart.displayName = 'SqlChart'

export default SqlChart
