import useApi from './use-api'
import { ObservabilityService } from 'gen/service/observability'
import { MetricInfo as MetricInfoProto } from '../../gen/service/observability'
import { useMemo } from 'react'
import { useProjectVersions } from './use-project-versions'
import { Project } from '../../gen/service/common'

export type FlatMetricInfo = Omit<MetricInfoProto, 'contractName' | 'contractAddress'> & {
  contractName: string
  contractAddress: string
}

export function useMetrics(
  projectId?: string,
  fromShare?: { shareId?: string; project?: Project },
  fromExternalProject?: { projectId?: string; externalProjectId?: string }
) {
  const { currentVersion, project } = useProjectVersions()
  const { data: metricsResponse, loading } = useApi(
    ObservabilityService.GetMetrics,
    projectId && {
      projectId: fromExternalProject?.externalProjectId ?? projectId,
      // when request project id is not equal to current project id,
      // we are asking for external project metrics, always use the latest version
      version: fromExternalProject?.externalProjectId ? undefined : currentVersion
    },
    false,
    {
      fromShare,
      fromExternalProject
    }
  )

  const flatMetrics = useMemo(() => {
    const contracts: FlatMetricInfo[] = []
    for (const m of metricsResponse?.metrics || []) {
      const names = m.contractName || []
      for (let i = 0; i < names.length; i++) {
        const contract = names[i]
        if (!contracts[contract]) {
          contracts[contract] = []
        }
        const addresses = m.contractAddress || []
        contracts.push({
          name: m.name,
          displayName: m.displayName,
          contractName: names[i],
          contractAddress: addresses[i],
          chainId: m.chainId
        })
      }
    }
    return contracts
  }, [metricsResponse?.metrics])

  return {
    flatMetrics: flatMetrics,
    metrics: metricsResponse?.metrics || [],
    loading
  }
}
