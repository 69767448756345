import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import PageHeader from 'components/common/header/PageHeader'
import { dateTimeToString, DateTimeValue } from 'lib/time'
import { useTimeRangeWithDefaultFromStorage } from 'lib/data/use-timerange'
import TimeRangePicker from 'components/timerange/TimeRangePicker'
import DashboardComponent from 'components/dashboard/Dashboard'
import { QueryRangeContext } from 'lib/context/query-range-context'

import Link from 'next/link'
import { TemplateVariableToolbar } from 'components/tmplvar/TemplateVariableToolbar'
import { createStore, Provider } from 'jotai'
import { dashboardAtom } from 'components/dashboard/atoms'
import { OpenGraphTags } from 'components/common/OpenGraphTags'
import { Project } from '@sentio/service/common'
import { Dashboard } from '@sentio/service/web'
import { ThemeSwitch } from 'components/common/input/ThemeSwitch'

interface Props {
  params: {
    dashboard: Dashboard
    project: Project
    host?: string
    shareId: string
  }
}

const SharedDashboard = ({ params }: Props) => {
  const router = useRouter()
  const { shareId } = router.query
  const storeRef = useRef<any>()
  if (!storeRef.current) {
    storeRef.current = createStore()
  }

  const { dashboard, project } = params
  useEffect(() => {
    if (storeRef.current) {
      storeRef.current.set(dashboardAtom, dashboard)
    }
  }, [dashboard])

  const emptyFn = useCallback(() => {
    // do nothing
  }, [])

  const { startTime, endTime, tz, setTimeRange, refresh } = useTimeRangeWithDefaultFromStorage(`sharing.${shareId}`)
  const changeTimeRange = useCallback(
    (start?: DateTimeValue, end?: DateTimeValue) => {
      localStorage.setItem(`sharing.${shareId}.startTime`, dateTimeToString(start))
      localStorage.setItem(`sharing.${shareId}.endTime`, dateTimeToString(end))

      setTimeRange(start, end)
    },
    [shareId, setTimeRange]
  )
  const context = useMemo(() => {
    return { isShare: true, shareId: shareId as string, shareProject: project }
  }, [shareId, project])

  return (
    <div data-testid="sharing-page" className="flex h-screen w-full flex-col">
      <OpenGraphTags
        name={dashboard.name}
        description={dashboard.description}
        imageUrl={`https://${params.host ?? 'app.sentio.xyz'}/api/og/share/${params.shareId}.png`}
        url={`https://${params.host ?? 'app.sentio.xyz'}/share/${params.shareId}`}
      />

      <PageHeader
        title={
          <>
            <div className="flex flex-shrink-0 items-center pl-2 sm:pl-3 lg:pl-4">
              <Link href="/">
                <img className="h-7 w-auto" src="/logo-mini.png" alt="Sentio" />
              </Link>
            </div>
            <h1
              data-testid="dashboard-title"
              className="text-text-foreground max-w-xs truncate pl-2 text-base font-normal sm:pl-3 lg:pl-4"
            >
              {dashboard?.name ?? 'Dashboard'}
            </h1>
          </>
        }
      >
        <div className="flex items-center gap-4">
          <ThemeSwitch />
          <TimeRangePicker
            startTime={startTime}
            endTime={endTime}
            tz={tz}
            onChange={changeTimeRange}
            onRefresh={refresh}
          />
        </div>
      </PageHeader>
      <Provider store={storeRef.current}>
        <QueryRangeContext.Provider value={context}>
          {dashboard?.extra?.templateVariables && Object.keys(dashboard.extra.templateVariables).length > 0 && (
            <TemplateVariableToolbar projectId={dashboard?.projectId} variables={dashboard.extra.templateVariables} />
          )}
          {dashboard && (
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
              <DashboardComponent
                dashboard={dashboard}
                allowEdit={false}
                onRemovePanel={emptyFn}
                onEditPanel={emptyFn}
                onLayoutChanged={emptyFn}
                onClonePanel={emptyFn}
                saving={false}
              />
            </div>
          )}
        </QueryRangeContext.Provider>
      </Provider>
    </div>
  )
}

export default SharedDashboard

export async function getServerSideProps({ req, params }) {
  const { shareId } = params
  const hostname = process.env['NEXT_PUBLIC_HOST'] || req.headers['host']

  const api = `https://${hostname}/api/v1/sharing_dashboard/${shareId}`

  try {
    const res = await fetch(api)
    if (res.ok) {
      const response = await res.json()
      const { dashboard, project } = response
      return {
        props: {
          params: {
            dashboard,
            project,
            host: hostname,
            shareId
          }
        }
      }
    }
  } catch (e) {
    console.error(e)
    return {
      notFound: true
    }
  }
}
